import type { I18nOptions } from 'vue-i18n'
import { createI18n } from 'vue-i18n'

import de from '@/locales/de.yaml'
import en from '@/locales/en.yaml'

const messages: I18nOptions['messages'] = {
  en,
  de
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('lang') || 'de',
  fallbackLocale: 'en',
  globalInjection: true,
  messages
})

export default i18n
