import type { RouteRecordRaw } from 'vue-router'

import EmptyLayout from '@/layouts/EmptyLayout.vue'

export const ACCEPT_EVENT = 'accept-meeting'
export const MEETING_NOT_FOUND = 'meeting-not-found'

export default [
  {
    path: '/accept-event/:event_id',
    name: ACCEPT_EVENT,
    component: () => import('./views/AcceptMeeting.vue'),
    meta: {
      auth: false,
      layout: EmptyLayout
    }
  },
  {
    path: '/accept-event/meeting-not-found',
    name: MEETING_NOT_FOUND,
    component: () => import('./views/MeetingNotFound.vue'),
    meta: {
      auth: false,
      layout: EmptyLayout
    }
  }
] as RouteRecordRaw[]
