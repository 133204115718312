import { useLocalStorage } from '@vueuse/core'
import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { type Ref, ref } from 'vue'
import { event, set } from 'vue-gtag'

import { useApiClient } from '@/api'
import type { Connection, PatchedUserProfile, User, UserProfile } from '@/generated/api'
import i18n from '@/i18n'

export const useAuthStore = defineStore('auth', () => {
  const apiClient = useApiClient()
  const user = ref<User | null>(null) as Ref<null | User>
  const testToken = useLocalStorage('calanda_auth_token', '') as Ref<string>
  const inviteToken = ref<string | null>(null)
  const scopes = ref<Connection | null>(null)

  async function fetchMe(): Promise<User | null> {
    if (user.value) {
      return user.value
    }
    user.value = await apiClient.api.apiUsersMeRetrieve()
    scopes.value = await apiClient.api.apiUsersMeConnectionsRetrieve()
    i18n.global.locale.value = user.value?.profile?.language || i18n.global.locale.value
    dayjs.locale(i18n.global.locale.value)
    localStorage.setItem('lang', i18n.global.locale.value)
    if (user.value.is_new_user) {
      event('register_user', {
        provider: user.value.profile?.provider,
        user_id: user.value.id,
        user_email: user.value.email
      })
    } else {
      event('login_user', {
        provider: user.value.profile?.provider,
        user_id: user.value.id,
        user_email: user.value.email
      })
    }
    set({ user_id: user.value.id, user_email: user.value.email })

    return user.value
  }

  function setDoNotShowConsent() {
    updateUserProfile({ do_not_show_consent_popup: true }).catch(() => {})
  }

  async function setDoNotShowCalendarTutorial() {
    user.value = await updateUserProfile({ show_calendar_tutorial: false })
  }

  function hidePermanentCloseButton() {
    if (!user.value?.profile && !user.value?.profile?.do_not_show_consent_popup) {
      return true
    }
    return !user.value?.profile?.do_not_show_consent_popup
  }

  function isUserLoggedIn(): boolean {
    return user.value?.logged_in ?? false
  }

  function getProvider(): string | null {
    switch (user.value?.profile?.provider) {
      case 'azuread-oauth2':
        return 'Microsoft'
      case 'google-oauth2':
        return 'Google'
      default:
        return null
    }
  }

  function hasAllScopes(): boolean {
    if (!scopes.value) {
      return false
    }
    return scopes.value.email && scopes.value.calendar && scopes.value.profile
  }

  async function updateUserProfile(data: PatchedUserProfile) {
    const profile = (await apiClient.api.apiUsersMeProfilePartialUpdate({
      requestBody: data
    })) as unknown as UserProfile

    user.value = { ...user.value, profile } as User

    return user.value
  }

  async function setInviteToken(token: string | null) {
    inviteToken.value = token
  }

  async function logout() {
    await apiClient.api.apiAuthLogoutRetrieve()
    user.value = null
  }

  async function getTestToken(): Promise<string | undefined> {
    return testToken.value
  }

  async function getInviteToken(): Promise<string | null> {
    return inviteToken.value
  }

  return {
    user,
    scopes,
    hasAllScopes,
    fetchMe,
    getProvider,
    setInviteToken,
    getInviteToken,
    isUserLoggedIn,
    logout,
    getTestToken,
    updateUserProfile,
    setDoNotShowConsent,
    hidePermanentCloseButton,
    setDoNotShowCalendarTutorial
  }
})
