import { computed, ref } from 'vue'

import type { AutocompleteOption } from '@/components/common/form/Autocomplete.vue'
import { useGoogleStore } from '@/store/google-script'

export function useGoogleAutocompleteSearch() {
  const googleStore = useGoogleStore()

  const location_suggestions = ref([])
  const searchLoading = ref(false)

  const googleScriptLoaded = computed(() => googleStore.googleScriptLoaded)

  async function searchPlace(searchValue: string): Promise<AutocompleteOption[]> {
    if (google && googleScriptLoaded.value) {
      searchLoading.value = true
      const autocomplete = new google.maps.places.AutocompleteService()
      const options = { input: searchValue }
      location_suggestions.value = await new Promise((resolve) => {
        autocomplete.getQueryPredictions(
          options,
          (
            results: google.maps.places.QueryAutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus
          ) => {
            if (status === 'OK') {
              resolve(
                results?.map((r: any) => {
                  return {
                    label: r.structured_formatting.main_text,
                    subtitle: r.structured_formatting.secondary_text,
                    value: r.place_id
                  }
                }) as unknown as never[]
              )
            } else {
              resolve([])
            }
          }
        )
      })
      searchLoading.value = false
    }
    return location_suggestions.value
  }

  function getGPlaceDetails(placeId: string) {
    const placesService = new google.maps.places.PlacesService(document.createElement('div'))
    return new Promise((resolve, reject) => {
      placesService.getDetails(
        {
          placeId: placeId,
          fields: ['geometry', 'formatted_address', 'url', 'opening_hours']
        },
        function (
          place: google.maps.places.PlaceResult | null,
          status: google.maps.places.PlacesServiceStatus
        ) {
          if (status === google.maps.places.PlacesServiceStatus.OK && place) {
            resolve(place)
          } else {
            reject(new Error(status))
          }
        }
      )
    })
  }

  async function selectPlace(selectedPlaceId: string) {
    const selectedPlace = location_suggestions.value.find(
      (suggestion: AutocompleteOption) => suggestion.value === selectedPlaceId
    ) as unknown as AutocompleteOption
    const label = selectedPlace ? selectedPlace.label : ''
    const place = (
      selectedPlaceId && typeof selectedPlaceId === 'string'
        ? await getGPlaceDetails(selectedPlaceId)
        : label
    ) as google.maps.places.PlaceResult
    return {
      name: label,
      location_address: place.formatted_address || '',
      latitude_location: place.geometry?.location?.lat() || null,
      longitude_location: place.geometry?.location?.lng() || null,
      remote_link: place.url || null,
      opening_hours: place.opening_hours ? place.opening_hours.periods : null
    }
  }

  return {
    selectPlace,
    searchPlace,
    searchLoading
  }
}
