import type { RouteRecordRaw } from 'vue-router'

import EmptyLayout from '@/layouts/EmptyLayout.vue'

export const NEW_MEETING = 'new-meeting'
export const NEW_MEETING_SLOTS = 'new-meeting-slots'
export const NEW_MEETING_INVITATION = 'new-meeting-invitation'

export default [
  {
    path: '/new-meeting',
    name: NEW_MEETING,
    component: () => import('./views/NewMeetingView.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/new-meeting/slots',
    name: NEW_MEETING_SLOTS,
    component: () => import('./views/NewMeetingSlots.vue'),
    meta: {
      auth: true,
      layout: EmptyLayout
    }
  },
  {
    path: '/new-meeting/invitation-preview',
    name: NEW_MEETING_INVITATION,
    component: () => import('./views/NewMeetingInvitationPreview.vue'),
    meta: {
      auth: true,
      layout: EmptyLayout
    }
  }
] as RouteRecordRaw[]
