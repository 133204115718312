<template>
  <VDropdown
    ref="popover"
    :distance="offset"
    class="dropdown"
    :boundary="boundaries"
    :delay="{ show: 0, hide: 0 }"
    v-bind="$attrs"
    @apply-show="onShow"
    @hide="onHide"
  >
    <slot :isOpen="dropdownOpened" :toggleTabindex="dropdownOpened ? groupTabindex : 0" />
    <template #popper="{ shown }">
      <Transitions name="dropdown">
        <div
          ref="contentWrapper"
          class="dropdown-content"
          :data-qa="dataQa + '-content'"
          :class="contentClasses"
        >
          <slot name="dropdown" :isOpen="shown" />
        </div>
      </Transitions>
    </template>
  </VDropdown>
</template>

<script setup lang="ts">
import { useEventListener } from '@vueuse/core'
import type { Dropdown } from 'floating-vue'
import { provide, ref } from 'vue'

import { GROUP_TAB_INDEX } from '@/components/common/dropdown/dropdownSettings'
import Transitions from '@/components/common/transition/Transitions.vue'

defineProps({
  contentClasses: { type: String },
  dataQa: { type: String, default: 'dropdown-content' }
})

const emits = defineEmits<{
  (e: 'show'): void
  (e: 'hide'): void
}>()

const contentWrapper = ref<HTMLDivElement | null>(null)
const popover = ref({} as typeof Dropdown)

const offset = ref(8)
const groupTabindex = ref(GROUP_TAB_INDEX)

const dropdownOpened = ref(false)

provide('dropdownOpened', dropdownOpened)

const boundaries = document.getElementById('layout-default')

const getChildMenuItems = () => {
  if (contentWrapper.value) {
    return Array.from(
      contentWrapper.value.getElementsByClassName('menu-item')
    ) as HTMLButtonElement[]
  }

  return [] as HTMLButtonElement[]
}

const focusNext = (nextIndex: (current: number, size: number) => number) => {
  const childMenuItems = getChildMenuItems().filter((el) => !el.disabled)
  if (childMenuItems.length) {
    const currentIndex = childMenuItems.findIndex((el) => el === document.activeElement)
    const index = nextIndex(currentIndex, childMenuItems.length)
    if (childMenuItems[index]) {
      childMenuItems[index].focus()
    }
  }
}

const focusUp = () => {
  focusNext((index, size) => {
    if (index >= 1) {
      return index - 1
    }
    return size - 1
  })
}

const focusDown = () => {
  focusNext((index, size) => {
    if (index >= 0 && index < size - 1) {
      return index + 1
    }
    return 0
  })
}

const onKeydown = (event: KeyboardEvent) => {
  switch (event.key) {
    case 'ArrowUp':
      focusUp()
      event.preventDefault()
      break

    case 'ArrowDown':
      focusDown()
      event.preventDefault()
      break

    default:
      break
  }
}

const hide = () => {
  popover.value.hide()
}

const show = () => {
  popover.value.show()
}

const onResize = () => {
  hide()
}

const onShow = (): void => {
  window.addEventListener('keydown', onKeydown)
  dropdownOpened.value = true
  emits('show')
}

const onHide = (): void => {
  window.removeEventListener('keydown', onResize)
  dropdownOpened.value = false
  emits('hide')
}

useEventListener(window, 'resize', onResize)

defineExpose({
  contentWrapper,
  hide,
  show
})
</script>

<style>
.dropdown {
  @apply flex cursor-pointer text-gray-800;

  :deep(.trigger) {
    @apply flex-grow;
  }
}

.dropdown-content {
  @apply flex max-h-[200px] flex-col overflow-x-hidden rounded px-1 py-1 shadow-popup-menu;
}

.dropdown-content-hidden {
  @apply hidden;
}

.dropdown-content.dropdown-content-no-paddings {
  @apply p-0;
}
</style>
