<template>
  <InputWrapper ref="root" :disabled="disabled" :error="error" :focused="focused">
    <textarea
      :data-qa="dataQa ? dataQa + '-textarea' : null"
      :id="inputId"
      ref="textarea"
      class="textarea"
      :class="{ 'auto-resize': autoResize, 'with-after': slots.after }"
      :value="modelValue"
      :placeholder="showPlaceholder"
      v-bind="$attrs"
      @focus="onFocus"
      @blur="onBlur"
      @input="inputListener"
    />
    <div v-if="slots.after" class="input-after">
      <slot name="after" />
    </div>
  </InputWrapper>
</template>

<script setup lang="ts">
import { nextTick, onMounted, type PropType, ref, useSlots, watch } from 'vue'

import {
  useInputWrapped,
  useInputWrappedEmits,
  useInputWrappedProps
} from '@/components/common/form/composables'
import InputWrapper from '@/components/common/form/InputWrapper.vue'

const props = defineProps({
  autoResize: { type: Boolean, default: true },
  modelValue: { type: String },
  transform: { type: Array as PropType<((v: string) => string)[]>, default: () => [] },
  inputId: { type: String },
  dataQa: { type: String, default: '' },
  ...useInputWrappedProps()
})

const emits = defineEmits(useInputWrappedEmits())
const slots = useSlots()

const { disabled, error, focused, showPlaceholder, onFocus, onBlur, inputHandler } =
  useInputWrapped(props, emits)

const root = ref<typeof InputWrapper>()
const textarea = ref<HTMLTextAreaElement>()

const resize = () => {
  if (textarea.value) {
    // const style = window.getComputedStyle(root.value?.$el as Element)
    textarea.value.style.height = 'auto'
    // const height = `${style.borderTopWidth} + ${style.borderBottomWidth} + ${textarea.value.scrollHeight}px`
    const height = `${textarea.value.scrollHeight}px`
    textarea.value.style.height = `calc(${height})`

    if (parseFloat(textarea.value.style.height) >= parseFloat(textarea.value.style.maxHeight)) {
      textarea.value.style.overflow = 'scroll'
      textarea.value.style.height = textarea.value.style.maxHeight
    } else {
      textarea.value.style.overflow = 'hidden'
    }
  }
}

const autoResizeIfPossible = () => {
  if (textarea.value?.offsetParent && props.autoResize) {
    nextTick(() => {
      resize()
    })
  }
}

onMounted(() => {
  nextTick(() => {
    autoResizeIfPossible()
  })
})

watch(() => props.modelValue, autoResizeIfPossible)

const inputListener = (event: Event) => {
  inputHandler(event, textarea.value as HTMLTextAreaElement, props.transform)
  if (props.autoResize) {
    nextTick(() => {
      resize()
    })
  }
}
</script>

<style scoped lang="postcss">
.textarea {
  @apply w-full p-3;

  &.auto-resize {
    @apply resize-none overflow-hidden;
  }
}

.input-after {
  @apply flex flex-shrink-0 pr-3 text-gray-600 transition-colors;

  &:has(.svg-icon) {
    @apply text-key-500;
  }
}
</style>
